<template>
  <div class="footer-wrapper">
    <footer>
        <div class="footer-about">
          <p>© {{ new Date().getFullYear() }}, ООО «Белорусские облачные технологии»</p>
          <a href="https://cctld.by/map/">Карта сайта</a>
        </div>
      <div class="footer-address">
        <p>220004, Республика Беларусь, г. Минск, ул. К. Цеткин, 24, пом. 602</p>
        <a href="https://cctld.by/contacts/">Схема проезда</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #eaf1f7;
}
.footer-about{
  margin-right: 20px;
  max-width: 250px;
  font-size: 14px;
}
.footer-about a{
  cursor: pointer;
}
.footer-address{
  max-width: 250px;
  font-size: 14px;
}
.footer-address a{
  cursor: pointer;
}
@media(max-width: 750px){
  p{
    font-size: 14px;
  }
  footer{
    padding-top: 15px;
  }
}
@media(max-width: 420px){
  p{
    font-size: 12px;
  }
}
</style>
