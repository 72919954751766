import { createWebHashHistory, createRouter } from 'vue-router';
import WhoisService from '@/views/WhoisService.vue';
import RegistrarList from '@/views/RegistrarList.vue';

const routes = [
    {
        path: '/',
        redirect: {name: 'WhoisService'},
    },
    {
        path: '/whois',
        name: 'WhoisService',
        component: WhoisService,
        props: route => ({ domainFromParams: route.query.name})
    },
    {
        path: '/registrars',
        name: 'RegistrarList',
        component: RegistrarList
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
