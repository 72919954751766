<template>
  <div class="container__app">
    <TheHeader/>

    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>

    <TheFooter/>


  </div>
</template>

<script>
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
//import ScrollTopButton from "./components/ScrollTopButton";
// import DomainCheck from './views/DomainCheck.vue';

export default {
  name: 'App',

  components: {
    // DomainCheck,
    TheHeader, TheFooter,
    //ScrollTopButton
  }
}
</script>

<style>
@import 'assets/whois.css';
</style>
