<template>
  <div class="header-wrapper">
    <header>
      <div class="container__flex--row menu">
        <div class="logo-items">
          <a href="https://cctld.by/">
            <img src="https://cctld.by/upload/medialibrary/1cc/1cc0411180dfd8d74cc322de48400bde.png" alt="BY">
          </a>
          <a href="https://cctld.by/">
            <img src="https://cctld.by/upload/medialibrary/ee7/ee7193fda905dc9e256871df2b06b873.png" alt="БЕЛ">
          </a>
        </div>
        <div class="nav-container">
          <div class="nav-about">
            <a href="https://cctld.by/news/">Новости</a>
            <a href="https://cctld.by/press/">Прессе</a>
            <a href="https://cctld.by/contacts/">Контакты</a>
          </div>
          <div class="nav">
            <div class="topnav" id="myTopnav">
              <a class="nav-elem" @click="goToLink('RegistrarList')">Регистраторы</a>
              <a class="nav-elem" @click="goToLink('WhoisService')">WHOIS</a>
              <!---------->
              <a class="bar-el" href="https://cctld.by/questions/">FAQ</a>
              <a class="bar-el" href="https://cctld.by/history/">История доменной зоны</a>
              <a class="bar-el" href="https://cctld.by/documents/">Документы</a>
              <a class="bar-el"
                 href="https://auction.cctld.by/index.php?language=%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9">Аукционы</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="mobile-menu">
          <div class="btn-menu">
            <svg id="Layer_1" @click="activateMenu" viewBox="0 0 512 512" style="width: 50px">
              <g>
                <g>
                  <path class="st0"
                        d="M388.3,201.9h-196c-9.9,0-17.9-9-17.9-20s8-20,17.9-20h196c9.9,0,17.9,9,17.9,20S398.2,201.9,388.3,201.9z"/>
                </g>
                <circle class="st0" cx="131.2" cy="181.9" r="25.5"/>
                <g>
                  <path class="st0"
                        d="M388.3,276h-196c-9.9,0-17.9-9-17.9-20s8-20,17.9-20h196c9.9,0,17.9,9,17.9,20S398.2,276,388.3,276z"/>
                </g>
                <circle class="st0" cx="131.2" cy="256" r="25.5"/>
                <g>
                  <path class="st0"
                        d="M388.3,350.1h-196c-9.9,0-17.9-9-17.9-20s8-20,17.9-20h196c9.9,0,17.9,9,17.9,20S398.2,350.1,388.3,350.1z"/>
                </g>
                <circle class="st0" cx="131.2" cy="330.1" r="25.5"/>
              </g>
            </svg>
          </div>
          <div class="mobile-logo">
            <div class="logo-items">
              <a href="https://cctld.by/"><img
                  src="https://cctld.by/upload/medialibrary/1cc/1cc0411180dfd8d74cc322de48400bde.png" alt="BY"></a>
              <a href="https://cctld.by/"><img
                  src="https://cctld.by/upload/medialibrary/ee7/ee7193fda905dc9e256871df2b06b873.png" alt="БЕЛ"></a>
            </div>
          </div>
        </div>
        <div class="mobile-menu-bar">
          <div class="mobile-menu-exit">
            <svg
                @click="activateMenu"
                width="34"
                height="34"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"/>
              <line x1="6" y1="6" x2="18" y2="18"/>
            </svg>
          </div>
          <div class="mobile-bar-container">
            <div class="mobile-bar-wrapper">
              <div class="mobile-bar">
                <a @click="goTo('RegistrarList')">Регистраторы</a>
                <a @click="goTo('WhoisService')">WHOIS</a>
                <!-------------->
                <a class="mobile-bar-el" href="https://cctld.by/questions/">FAQ</a>
                <a class="mobile-bar-el" href="https://cctld.by/history/">История доменной зоны</a>
                <a class="mobile-bar-el" href="https://cctld.by/documents/">Документы</a>
                <a class="mobile-bar-el"
                   href="https://auction.cctld.by/index.php?language=%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9">Аукционы</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>

export default {
  name: 'TheHeader',

  data() {
    return {
      activeMenu: true,
    }
  },
  methods: {
    goToLink(i) {

      let active = document.querySelectorAll('.nav-elem');
      if (this.$route.path === "/registrars" && i !== 'RegistrarList') {
        active.forEach((el) => {
          el.classList.remove('active');
        })
        active[1].classList.add('active');
      } else if (this.$route.path === "/whois" && i !== "WhoisService") {
        active.forEach((el) => {
          el.classList.remove('active');
        })
        active[0].classList.add('active');
      }
      this.$router.push({name: i});
    },
    activateMenu() {
      let menu = document.querySelector('.mobile-menu');
      let bar = document.querySelector('.mobile-menu-bar');
      if (this.activeMenu) {
        menu.style.display = 'none';
        bar.style.display = 'block';
        document.body.style.overflow = 'hidden';
        this.activeMenu = false;
      } else {
        menu.style.display = 'flex';
        bar.style.display = 'none';
        document.body.style.overflow = 'auto';
        this.activeMenu = true;
      }
    },
    goTo(text) {
      let menu = document.querySelector('.mobile-menu');
      let bar = document.querySelector('.mobile-menu-bar');
      this.$router.push({name: `${text}`});
      menu.style.display = 'flex';
      bar.style.display = 'none';
      document.body.style.overflow = 'auto';
      this.activeMenu = true;
    }
  },
  mounted() {
    let active = document.querySelectorAll('.nav-elem');
    if (this.$route.path === "/registrars") {
      active.forEach((el) => {
        el.classList.remove('active');
      })
      active[0].classList.add('active');
    } else if (this.$route.path === '/whois') {

      active.forEach((el) => {
        el.classList.remove('active');
      })
      active[1].classList.add('active');
    }
  }
}
</script>

<style scoped>
.active {
  color: black;
  cursor: auto;
}

.active:hover {
  color: black;
}

.nav-container {
  text-align: start;
  max-width: 800px;
  flex-basis: 100%;
}

.nav-about {
  display: flex;
  max-width: 300px;
}

.nav-about a {
  margin-right: 15px;
  color: #63696e;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  transition: .2s;
}

.nav-about a:hover {
  color: #d82f34;
  transition: .2s;
}

.menu {
  padding: 20px 0 0;
  border-bottom: 1px solid #d82f34;
  color: #2187de;
  justify-content: space-between;
  align-items: center;
}

.bar-el {
  line-height: 42px;
  color: #2187de;
  text-decoration: none;
}

.header-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #eaf1f7;
}

.logo-items img:first-child {
  margin-left: 20px;
  margin-right: 20px;
}

.st0 {
  fill: #d82f34;
}

.container__flex--column p {
  margin: 5px 0 5px 0;
}

.mobile {
  display: none;
}

h1 {
  margin-top: 15px;
}

.icon {
  margin-right: 10px;
}


@media (max-width: 959px) {
  .menu {
    display: none;
  }

  .mobile {
    display: block;
  }

  .mobile-bar-el {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .btn-menu img {
    width: 50px;
  }

  .header-wrapper {
    border-bottom: 1px solid #d82f34;
  }

  .mobile-menu {
    padding: 20px 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-bar {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }

  .mobile-bar a {
    font-size: 20px;
    margin-bottom: 25px;
    cursor: pointer;
  }

  .mobile-contact p {
    font-size: 20px;
  }

  .mobile-menu-bar {
    position: relative;
    display: none;
    width: 100%;
    height: 100vh;
  }

  .mobile-menu-exit {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .mobile-bar-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .logo-items img {
    width: 80px;
  }

  header {
    padding: 0;
  }
}

@media (max-width: 425px) {
  .logo-items img {
    width: 65px;
  }
  .logo-items img:first-child{
    margin-left: 5px;
    margin-right: 5px;
  }
}
</style>
