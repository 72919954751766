<template>
    <div id="cardItem" class="card card--elevation">
      <a :href="registrar.link" target="_blank">
      <div class="card__inner">
            <img :src="registrar.image">
            <h2>{{ registrar.name }}</h2>

        </div>
      </a>
    </div>
</template>

<script>
export default {
    name: 'CardItem',

    props: {
        registrar: {
            type: Object,
            required: true
        },
    }
}
</script>

<style scoped>
h2 {
    font-size: 18px;
  color: #312e25;
}

a{
  outline: none;
  text-decoration: none;
}

.card{
  padding: 10px;
}

@media(max-width: 1000px){
  h2{
    font-size: 16px;
  }
}
@media(max-width: 850px){
  h2{
    font-size: 14px;
  }
  img{
    margin: 10px 0;
    width: 80px;
  }
  .card{
    margin: 0 5px 30px;
    width: 255px;
  }
  .card__inner{
    height: 200px;
  }
}
@media(max-width: 650px){
  h2{
    font-size: 14px;
  }
  img{
    width: 100px;
  }
  .card{
    margin: 0 5px 30px;
    width: 200px;
  }
  .card__inner{
    height: 150px;
  }
}
@media(max-width: 520px){
  h2{
    font-size: 15px;
  }
  img{
    width: 80px;
  }
  .card{
    margin: 0 5px 30px;
    width: 250px;
  }
  .card__inner{
    height: 200px;
  }
}
</style>
