<template>
  <div id="registrarList" class="container__main">
    <div class="main-wrapper">
      <div class="title">
        <h2>Перечень регистраторов</h2>
      </div>
      <div class="text">
        <p>Согласно «<a href="https://cctld.by/documents/instruction-on-the-procedure-of-registration-of-domain/">Инструкции о порядке регистрации доменных имен в пространстве иерархических имен национального
          сегмента сети интернет</a>» регистрация доменов .BY и .БЕЛ может осуществляться юридическими лицами,
          аккредитованными техническим администратором национальной доменной зоны. В настоящее время аккредитованными
          регистраторами являются 6 компаний:</p>
      </div>
      <div class="items_wrapper">
        <div class="items container__flex--row justify-space-around" style="flex-wrap: wrap;">
          <CardItem
              v-for="registrar in registrarList" :key="registrar.name"
              :registrar="registrar"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardItem from '../components/CardItem.vue';

export default {
  name: 'RegistrarList',

  components: {
    CardItem,
  },

  data() {
    return {
      registrarList: [
        {
          name: 'ООО «Белорусские облачные технологии»',
          link: 'https://becloud.by',
          linkText: 'becloud.by',
          image: '/logotypes/becloud.jpg'
        },
        {
          name: '«Открытый контакт», ООО',
          link: 'http://www.domain.by/',
          linkText: 'www.domain.by',
          image: '/logotypes/domain.jpg'
        },
        {
          name: '«ТриИнком», ООО ',
          link: 'https://hb.by/domain-checker.aspx',
          linkText: 'hb.by/domain-checker.aspx',
          image: '/logotypes/hb.png'
        },
        {
          name: '«Суппорт чейн», ООО',
          link: 'https://www.hostfly.by/',
          linkText: 'www.hostfly.by',
          image: '/logotypes/hostfly.png'
        },
        {
          name: '«Активные технологии», ООО',
          link: 'http://www.active.by/ru-by/services/domains/',
          linkText: 'www.active.by/ru-by/services/domains/',
          image: '/logotypes/activecloud.png'
        },
        {
          name: '«Надежные программы», ООО',
          link: 'https://hoster.by/service/domains/',
          linkText: 'hoster.by/service/domains/',
          image: '/logotypes/hoster.png'
        },
      ]
    }
  },

  created() {
    this.registrarList = this.registrarList.sort(() => Math.random() - 0.5);
  },
}
</script>

<style scoped>
.main-wrapper {
  padding: 50px 40px 0px;
  margin: 0 auto;
  height: 100%;
  max-width: 1280px;
  flex-basis: 100%;
  background-color: white;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1;
  color: #312e25;
}
.text{
  max-width: 800px;
  margin: 0 auto 50px;
}

.container__main {
  background-color: #eaf1f7;
}

.items_wrapper {
  display: flex;
  justify-content: center;
}

.items {
  max-width: 1200px;
  flex-basis: 100%;
}

@media (max-width: 850px) {
  .items {
    flex-wrap: wrap;
  }

  .main-wrapper{
    padding: 20px 10px 0;
  }
  .text{
    text-align: start;
    margin-bottom: 20px;
  }
  .title {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 14px;
  }
  .text p{
    font-size: 14px;
  }
}

</style>
