<template>

  <div id="domainCheck" class="container__main">
    <div class="main-wrapper">
      <div class="mobile-text">
        <h2>Сервис Whois</h2>
      </div>
      <div class="container__flex--row justify-center text-container">
        <p v-if="!isChecked" class="text--info">
          Сервис Whois позволяет быстро получить всю информацию о регистрации домена,
          например, дату регистрации и возраст домена, или узнать контакты,
          по которым можно связаться с организацией или человеком, чей домен вас заинтересовал.
        </p>
      </div>
      <div class="container__flex--row justify-center">
        <input v-model.trim="domain"
               @keypress.enter="getDomainInfo"
               class="whois-input"
               placeholder="Введите доменное имя">
        <button class="btn-get"
                style="margin-left: 40px"
                @click="getDomainInfo">Проверить
        </button>
      </div>

      <div class="container-data justify-center">
        <article v-if="domainStatus">
          <h2 class="domain-status">{{ domainStatus.name }}</h2>
          <p class="status-text">{{ domainStatus.text }}</p>
        </article>

        <article v-if="domainInfo" class="domain-info">
          <h2 v-if="domainCheck">Результаты проверки домена {{ domainInfo.name }}</h2>
          <h3>Информация о домене</h3>
          <h4>Регистратор:</h4>
          <p v-for="val in domainInfo.registrar" :key="val">{{ val }}</p>
          <h4>Владелец домена:</h4>

          <p v-if="domainInfo.protected">Владелец скрыл информацию о домене!</p>
          <div v-else>
            <p>{{ domainInfo.registrant.name }}</p>
            <p>{{ fastenAddress(domainInfo.registrant.address) }}</p>
            <p>Регистрационный или иной идентификационный номер: {{ domainInfo.registrant.unp }}</p>
            <p>Телефон: {{ domainInfo.registrant.phone }}</p>
            <p>E-mail: {{ domainInfo.registrant.email }}</p>
          </div>

          <div v-if="domainInfo.ns && domainInfo.ns.host && domainInfo.ns.host.length">
            <h4>DNS-серверы:</h4>
            <p v-for="host in domainInfo.ns.host" :key="host">{{ host.name }}</p>
          </div>

          <h4>Состояние: </h4>
          <p>Дата создания: {{ trimLength(domainInfo.create_date) }}</p>
          <p>Дата последнего обновления: {{ trimLength(domainInfo.update_date) }}</p>
          <p>Дата окончания: {{ trimLength(domainInfo.expires_date) }}</p>
          <button type="button" class="btn-get btn-link" @click="showModal = true">
            Связаться с владельцем домена
          </button>
        </article>
      </div>
    </div>
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <span class="close-button" @click="showModal = false">&times;</span>
        <div class="form-message">
          <MessageItem :domain-name="domainInfo.name"></MessageItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageItem from '../components/MessageItem.vue';
import {ERROR_MESSAGES} from "@/constants/errorMessages";

export default {
  name: 'DomainCheck',

  components: {
    MessageItem,
  },

  props: {
    domainFromParams: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      domain: null,
      domainInfo: null,
      domainStatus: null,
      domainCheck: false,
      isChecked: false,
      showModal: false
    }
  },

  watch: {
    domainFromParams(newVal) {
      this.domain = newVal;
      this.getDomainInfo();
    }
  },

  mounted() {
    this.getDomainFromParams();
  },

  created() {
    document.addEventListener('click', this.closeModal);
  },
  unmounted() {
    document.removeEventListener('click', this.closeModal);
  },
  methods: {
    getDomainInfo() {
      this.domainCheck = true;
      this.isChecked = true;
      this.domainInfo = null;

      // this.domainInfo = {
      //   "name": "becloud.by",
      //   "registrar": {
      //     "rus": "ООО \"Белорусские облачные технологии\"",
      //     "eng": "Belarusian Cloud Technologies LLC"
      //   },
      //   "registrant": {
      //     "name": "Иванов Сергей Николаевич",
      //     "address": {
      //       "country": "BY",
      //       "city": "г. Минск",
      //       "region": "Минский район",
      //       "index": "220000",
      //       "street": "ул. Лобанка",
      //       "building": "81", "office": "200"
      //     },
      //     "phone": "+375297796530",
      //     "email": "sergey@tutby.com",
      //     "unp": "100160363"
      //   },
      //   "protected": false,
      //   "protected_phone": false,
      //   ns: {"hosts": ["ns1.example.by", "ns2.example.com"],},
      //   "create_date": "2021-10-18T10:11:15.624596Z",
      //   "update_date": "2021-10-18T13:25:32.582026Z",
      //   "expires_date": "2022-10-18T10:11:15.631494Z"
      // };
      this.domainStatus = null;
      if (!this.domain) {
        this.domainStatus = {
          name: 'Ошибка',
          text: 'Укажите доменное имя.',
        };
        return;
      }

      let dom = this.domain;
      this.axios.get(`/whois?name=${this.domain}`)
          .then(response => {
            this.domainInfo = response.data;
            if (dom.length !== response.data.name.length) {
              this.domainCheck = false;
              this.domainStatus = {
                name: `Результаты проверки домена ${dom}`,
                text: `Доменное имя ${dom} доступно, для регистрации обратитесь к владельцу домена ${response.data.name}.`,
              };
            }
          })
          .catch(error => {
            const errorMessage = ERROR_MESSAGES[error.response?.data?.error] || ERROR_MESSAGES['Invalid domain'];
            this.domainStatus = {
              name: `Результаты проверки домена ${dom}`,
              text: errorMessage.replace('{domain}', dom),
            };
          });
    },

    // TODO: replace console.logs and alerts with vue-notify!

    fastenAddress(obj) {
      return Object.values(obj).join(', ');
    },

    trimLength(value) {
      return value.substring(0, 10);
    },

    getDomainFromParams() {
      if (this.domainFromParams) {
        this.domain = this.domainFromParams;
        this.getDomainInfo();
      }
    },
    closeModal(event) {
      if (event.target.className === 'modal') {
        this.showModal = false;
      }
    },
  }
}
</script>

<style scoped>
.domain-status {
  margin-bottom: 10px;
}

.container-data {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-get {
  margin-bottom: 3px;
  border: none;
  box-shadow: 0 3px 0 #70644E;
  background: linear-gradient(to bottom, #aa9d83 0, #a79a7f 20%, #93866b 100%);
  text-shadow: 0 1px 1px rgba(0, 0, 0, .21);
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 10px 24px 11px;
  font-size: 24px;
  line-height: 1;
  border-radius: 6px;
  color: #fff;
  outline: none;
}

.btn-get:hover {
  box-shadow: 0 3px 0 #5B5140;
  background: linear-gradient(to bottom, #9a8d73 0, #96896f 20%, #82765e 80%, #7d725a 100%);
}

.whois-input {
  color: #312e25;
  font-size: 20px;
  line-height: 26px;
  background: #fff;
  height: 48px;
  border: 1px solid #312e25;
  border-radius: 6px;
  transition: box-shadow .4s ease-out;
  padding: 4px 20px;
  outline: none;
}

.main-wrapper {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 40px;
  flex-basis: 100%;
  height: 100%;
  background-color: white;
}

#domainCheck {
  background-color: #eaf1f7;
}


.text--info {
  width: 60%;
}

.mobile-text {
  padding-top: 5%;
  display: block;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 1;
  color: #312e25;
}

.container__flex--row {
  margin-bottom: 30px;
}

button, input {
  padding: 5px;
}

.domain-info {
  text-align: left;
}

.domain-info > h2, h3, h4 {
  margin: 20px 0 10px;
}

.form-row > input, textarea {
  width: 70%;
  margin: 10px;
}

textarea {
  overflow: auto;
  resize: vertical;
}

input, button {
  font-size: 16px;
}

@media (max-width: 1250px) {
  .text--info {
    width: 90%;
  }
}

@media (max-width: 750px) {
  .text--info {
    width: 90%;
    font-size: 16px;
  }

  .main-wrapper {
    padding: 0 10px;
  }
}

@media (max-width: 500px) {
  .btn-get {
    margin-left: 10px !important;
    font-size: 18px;
  }

  .whois-input {
    font-size: 16px;
    height: 40px;
    padding: 4px 10px;
  }
}

@media (max-width: 425px) {
  .text--info {
    font-size: 16px;
    text-align: start;
  }

  .text-container {
    margin-bottom: 10px;
  }

  .mobile-text {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .domain-status {
    text-align: left;
  }

  .status-text {
    text-align: left;
  }

}

@media (max-width: 379px) {
  .domain-status {
    font-size: 20px;
  }

  .text--info {
    font-size: 14px;
  }

  .mobile-text {
    font-size: 16px;
  }

  .btn-get {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .btn-get {
    margin-left: 5px !important;
    font-size: 14px;
    padding: 10px 18px 11px;
  }

  .whois-input {
    font-size: 14px;
    height: 38px;
    padding: 2px 2px;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  bottom: 15px;
  left: 5px;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.btn-link{
  margin-top: 16px;
}
</style>
