import { createApp } from 'vue';
import App from './App.vue';

import axios from 'axios';
import router from './router';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faPhone } from "@fortawesome/free-solid-svg-icons";

library.add(faChevronUp, faPhone);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.config.globalProperties.axios = axios;
app.mount('#app');
