<template>
  <div class="form-message">
    <h2 class="title">Отправить сообщение владельцу домена</h2>
    <div>
      <form class="form-contact" @submit.prevent="validateForm">

        <div class="form-row">
          <p>Электронная почта:</p>
          <input v-model="email"
                 type="email"
                 class="input"
                 required maxlength="50"
                 placeholder="email@example.by">
          <p class="error-message"
             v-if="emailErrorMessage"
             :class="{ 'visible': emailErrorMessage }">
            {{ emailErrorMessage }}
          </p>
        </div>

        <div class="form-row">
          <p>Телефон:</p>
          <vue-tel-input class="input"
                         v-model="phone"
                         maxlength="25"
                         :inputOptions="{showDialCode: true,placeholder: 'Пример: +375 29 1234567'}"
                         :inputStyle="{ 'font-size': '16px' }"
                         :required="true"
                         :disabled="false"
                         @update="updatePhoneNumber"
          />
          <p class="error-message" v-if="phoneErrorMessage">
            {{ phoneErrorMessage }}</p>
        </div>

        <div class="form-row">
          <p>Сообщение:</p>
          <div class="radio-group">
            <label class="checkbox-label"
                   v-for="option in messages"
                   :key="option.id">
              <input v-model="selectedMessage"
                     :value="option.id"
                     type="radio"
                     @change="updateMessage(option.id)"
              >
              {{ option.rus }}
            </label>
          </div>
        </div>

        <div class="form-row" style="justify-content: center">
          <vue-recaptcha ref="recaptcha"
                         size="invisible"
                         :sitekey="siteKey"
                         :loadRecaptchaScript="true"
                         @verify="sendForm"
                         @expired="resetCaptchaOnExpire"
          ></vue-recaptcha>
          <button type="submit" class="submit-btn">Отправить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha';
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: 'MessageItem',

  components: {VueRecaptcha, VueTelInput},

  props: {
    domainName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      email: null,
      emailErrorMessage: null,

      messages: null,
      selectedMessage: null,

      phone: '',
      phoneErrorMessage: '',

      siteKey: '6LdR3nAeAAAAAASCPNY9lLWQB4Ow_PFys95TWQ4h'
    };
  },

  mounted() {
    this.getMessages();
  },

  methods: {
    updatePhoneNumber(value) {
      this.phone = value;
    },

    sendForm(recaptchaToken) {
      let requestData = {
        domain: this.domainName,
        from: this.email,
        message_id: this.selectedMessage,
        phone: this.phone,
        recaptcha_token: recaptchaToken
      };

      this.axios.post('/sendmail', requestData)
          .then(() => {
            alert('Сообщение успешно отправлено!');
          })
          .catch(() => {
            alert('Произошла ошибка при отправке сообщения!');
          });
    },

    getMessages() {
      // this.axios.get('http://195.50.17.223:8080/messages?name=tut.by').then((res) => {
      this.axios.get(`/messages?name=${this.domainName}`).then((res) => {
        this.messages = res.data;
      });
    },

    validateForm() {
      this.validateEmail();
      this.validatePhone();

      if (this.emailErrorMessage || this.phoneErrorMessage) {
        return;
      }
      this.$refs.recaptcha.execute();
    },

    resetCaptchaOnExpire() {
      this.$refs.recaptcha.reset();
    },

    updateMessage(id) {
      this.selectedMessage = id;
      console.log(this.selectedMessage)
    },

    validateEmail() {
      if (!this.email) {
        this.emailErrorMessage = 'Пожалуйста, введите электронную почту.';
      } else if (!this.isValidEmail(this.email)) {
        this.emailErrorMessage = 'Пожалуйста, введите корректную электронную почту.';
      } else {
        this.emailErrorMessage = null;
      }
    },

    validatePhone() {
      if (!this.phone) {
        this.phoneErrorMessage = 'Пожалуйста, введите номер телефона. Пример: +375 29 1234567';
      } else if (!this.isValidPhone(this.phone)) {
        this.phoneErrorMessage = 'Пожалуйста, введите корректный номер телефона. Пример: +375 29 1234567';
      } else {
        this.phoneErrorMessage = '';
      }
    },

    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    isValidPhone(phone) {
      const sanitizedNumber = phone.replace(/\D/g, '');
      return sanitizedNumber.length >= 9 && sanitizedNumber.length <= 15;
    }
  }
}

</script>
<style scoped>

.form-contact {
  max-width: 500px;
  width: 100%;

  p {
    text-align: left;
  }
}

.title {
  margin: 20px 0;
}

.input {
  width: 100%;
  padding: 4px 10px;
  margin: 10px 0;
  color: #312e25;
  font-family: inherit;
  font-size: 16px;
  line-height: 26px;
  background: #fff;
  height: 38px;
  border: 1px solid #312e25;
  border-radius: 6px;
  transition: box-shadow .4s ease-out;
  outline: none;
}

.submit-btn {
  margin-bottom: 3px;
  border: none;
  box-shadow: 0 3px 0 #70644E;
  background: linear-gradient(to bottom, #aa9d83 0, #a79a7f 20%, #93866b 100%);
  text-shadow: 0 1px 1px rgba(0, 0, 0, .21);
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 10px 24px 11px;
  font-size: 16px;
  line-height: 1;
  border-radius: 6px;
  color: #fff;
  outline: none;
}

.submit-btn:hover {
  box-shadow: 0 3px 0 #5B5140;
  background: linear-gradient(to bottom, #9a8d73 0, #96896f 20%, #82765e 80%, #7d725a 100%);
}

.checkbox-label {
  display: block;
  margin-bottom: 10px;
}

.checkbox-label input {
  margin-right: 5px;
}

.radio-group {
  margin: 12px 0;
}

.error-message {
  color: red;
}

.error-message {
  color: red;
  margin: 6px 0 6px;
  font-size: 14px;
}

.error-message.visible {
  display: block;
}

@media (max-width: 400px) {
  .title {
    font-size: 20px;
  }

}
</style>
